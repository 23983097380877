<template>
  <div class="container-enterprises">
    <header-content title="Meus Empreendimentos">
      <p class="subtitle">
        Gerencie os empreendimentos que você salvou para futuras consultas e
        aqueles pelos quais demonstrou interesse como potenciais
        investimentos, visualizando quais deles aceitaram sua manifestação de interesse.
      </p>
    </header-content>

    <div class="tabs-container">
      <div class="tabs">
        <div v-for="(tab, index) in tabs" :key="index" class="tab" @click="handleActiveTab(index)"
          :class="{ active: activeTab === index }">
          {{ tab.title }}
        </div>
      </div>
      <div class="header-slot-template">
        <div class="control-suface">
          <b-button class="mr-2 d-flex align-items-center" :disabled="!surfaceFull" @click="toggleSurface">
            <img v-if="!surfaceFull" class="icon" src="../../assets/not-full.svg" alt="Icon Not Full" />
            <img v-else class="icon" src="../../assets/not-full-black.svg" alt="Icon Not Full" />
          </b-button>
          <b-button class="d-flex align-items-center" :disabled="surfaceFull" @click="toggleSurface">
            <img v-if="surfaceFull" class="icon" src="../../assets/full.svg" alt="Icon Full" />
            <img v-else class="icon" src="../../assets/full-black.svg" alt="Icon Full" />
          </b-button>
        </div>
        <div class="buttons-filter-ordering">
          <PainelDrop :show="showdrop" @emitShowDrop="handleShowDrop" @emitSendFilter="handleFilter" />
          <select v-model="selectedSort" @change="handleSelectedSort" class="selectSort">
            <option disabled value="" selected>Ordenar por</option>
            <option v-for="option in ordering" v-bind:value="option.value" :key="option.value">
              {{ option.title }}
            </option>
          </select>
        </div>
      </div>

      <div class="tab-content">
        <div class="content-enterprises" v-if="onLoading">
          <div class="map">
            <MapLoading />
          </div>
          <div class="surface">
            <div v-for="index in 12" :key="index">
              <CardLoading />
            </div>
          </div>
        </div>
        <div
          v-if="requestdata.length > 0 && !onLoading"
          class="content-enterprises"
          :class="{ 'content-full': surfaceFull }"
        >
          <div class="map" v-if="!surfaceFull">
            <MapLeaflet
              :coordenades="coordinates"
              :request="requestdata"
              :isminimap="true"
              @emitSaveVenture="saveVenture"
            />
          </div>
          <div class="surface" :class="{ 'surface-full': surfaceFull }">
            <div v-for="(data, index) in requestdata" :key="index">
              <Card :status="tabs[activeTab].label" :data="data" @emitSaveVenture="saveVenture" />
            </div>
          </div>
        </div>
        <div class="no-content" v-if="isNoContent">
          <div class="box-no-content">
            <img :src="noContent.icon" class="icon-no-content" alt="Icone" />
          </div>
          <div class="title-no-content">
            <span>{{ noContent.title }}</span>
          </div>
          <div class="description-no-content">
            <span>{{ noContent.description }}</span>
          </div>
          <router-link to="/search">
            <button v-if="activeTab == 1 || activeTab == 2">
              <img src="../../assets/icon-search.svg" class="icon-button-search" alt="" />
              Buscar empreendimentos
            </button>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import HeaderContent from "@/components/Header.vue";
import Card from "@/components/Card.vue";
import MapLeaflet from "@/components/MapVueLeaflet.vue";
import ImgInterests from "../../assets/icon-building-check.svg";
import ImgSaved from "../../assets/icon-bookmark.svg";
import PainelDrop from "@/components/PainelDropdown.vue";
import MapLoading from "@/components/loading/Map.vue";
import CardLoading from "@/components/loading/Card.vue";

export default {
  components: {
    HeaderContent,
    Card,
    MapLeaflet,
    PainelDrop,
    MapLoading,
    CardLoading,
  },
  data() {
    return {
      coordinates: [],
      requestdata: [],
      surfaceFull: false,
      showdrop: false,
      onLoading: false,
      isNoContent: false,
      noContent: null,
      activeTab: 0,
      selectedSort: "",
      saving: false,
      dataFilter: {
        region: "",
        state: "",
        city: "",
        neighborhood: "",
        typeEnterprise: [],
        activitySegmentation: [],
        sizeEnterprise: [],
        numbersUnits: [],
        unitsSold: [],
        phaseWork: [],
        generalProgress: [],
      },
      tabs: [
        { title: "Aceitos", label: "aceitos" },
        { title: "Meus interesses", label: "interesses" },
        { title: "Salvos", label: "salvos" },
        { title: "Recusados", label: "recusados" },
      ],
      ordering: [
        { title: "Alfabética", value: 0 },
        { title: "Mais recente", value: 1 },
        { title: "Menos recente", value: 2 },
      ],
      content_noContent: [
        {
          icon: ImgInterests,
          title: "Ainda não há empreendimentos que aceitaram o seu interesse",
          description:
            "Após a Incorporadora aceitar o seu interesse, o empreendimento irá aparecer aqui.",
        },
        {
          icon: ImgInterests,
          title: "Ainda não há empreendimentos em que demostrei interesse",
          description:
            "Busque empreendimentos e demostre interesse naqueles que você deseja investir.",
        },
        {
          icon: ImgSaved,
          title: "Ainda não há empreendimentos salvos",
          description:
            "Busque empreendimentos e salve-os para consultas futuras.",
        },
        {
          icon: ImgInterests,
          title: "Ainda não há empreendimentos recusados",
          description:
            "Após a Incorporadora recusar o seu interesse, o empreendimento irá aparecer aqui.",
        },
      ],
    };
  },
  mounted() {
    this.getEnterpriseAccepted();

    if (this.$route.query.tabIndex) {
      const tab = parseInt(this.$route.query.tabIndex);
      this.handleActiveTab(tab);
    }
  },
  methods: {
    handleShowDrop(value) {
      this.showdrop = value;
    },
    handleShow() {
      this.showModal = !this.showModal;
    },
    toggleSurface() {
      this.surfaceFull = !this.surfaceFull;
    },
    handleActiveTab(value, filtered = false) {
      this.$router.push({path: this.$route.fullPath, query: {tabIndex: value} });

      const tabFunctions = [
        this.getEnterpriseAccepted,
        this.getEnterpriseInterests,
        this.getEnterpriseSaved,
        this.getEnterpriseRefused,
      ];

      if (filtered || (value !== this.activeTab && tabFunctions[value])) {
        tabFunctions[value]();
        this.activeTab = value;
      }
    },
    getEnterpriseAccepted() {
      const url =
        "/api/vitrine/fundo_investimento/painel_empreendimentos/aceitos";
      this.getData(url);
    },
    getEnterpriseInterests() {
      const url =
        "/api/vitrine/fundo_investimento/painel_empreendimentos/interesses";
      this.getData(url);
    },
    getEnterpriseSaved() {
      const url =
        "/api/vitrine/fundo_investimento/painel_empreendimentos/salvos";
      this.getData(url);
    },
    getEnterpriseRefused() {
      const url =
        "/api/vitrine/fundo_investimento/painel_empreendimentos/recusados";
      this.getData(url);
    },
    async getData(url) {
      this.onLoading = true;
      this.isNoContent = false;
      this.coordinates = [];
      this.requestdata = [];

      axios
        .get(url + `?${this.setFilter().toString()}`, {
          headers: {
            id_cliente: this.$session.get("master").id_cliente,
          },
        })
        .then((response) => {
          const responseData = response.data;

          if (responseData.length === 0) {
            this.onLoading = false;
            this.isNoContent = true;
            this.noContent = this.content_noContent[this.activeTab];
          } else {
            this.requestdata = responseData.map((item) => {
              return { ...item.empreendimento, _id: item._id };
            });
            this.requestdata.forEach((item) => {
              this.coordinates.push({
                id: item._id,
                latitude: !item.coordenadas ? 0 : item.coordenadas.latitude,
                longitude: !item.coordenadas ? 0 : item.coordenadas.longitude,
              });
            });
            this.onLoading = false;
            this.isNoContent = false;
            this.noContent = [];
          }
        })
        .catch((error) => {
          console.error("Erro ao fazer requisição de empreendimentos:", error);
          this.onLoading = false;
          this.isNoContent = true;
          this.noContent = this.content_noContent[this.activeTab];
        });
    },
    async saveVenture(idVenture) {

      if (this.saving) return;

      this.saving = true;

      const selectIndex = this.requestdata.findIndex(
        (Ifill) => Ifill._id == idVenture.id
      );

      if (!idVenture.status) {
        try {

          await axios
            .post("/api/vitrine/fundo_investimento/painel_empreendimentos/salvos",
              { _id_empreendimento: idVenture.id },
              {
                headers: {
                  id_cliente: this.$session.get("master").id_cliente,
                },
              })
            .then((response) => {
              this.saving = false;
              this.requestdata[selectIndex].salvo = true;
            });
        } catch (error) {
          this.saving = false;
          console.log(
            "Erro ao adicionar salvo:",
            error
          );
        }
      }

      if (idVenture.status) {
        try {

          await axios
            .delete(`/api/vitrine/fundo_investimento/painel_empreendimentos/salvos/${idVenture.id}`,
              {
                headers: {
                  id_cliente: this.$session.get("master").id_cliente,
                },
              })
            .then((response) => {
              this.saving = false;
              this.requestdata[selectIndex].salvo = false;
            });
        } catch (error) {
          this.saving = false;
          console.log(
            "Erro ao remover salvo:",
            error
          );
        }
      }

    },
    handleSelectedSort() {
      switch (this.selectedSort) {
        case "0":
          this.requestdata = this.requestdata
            .slice()
            .sort((a, b) =>
              a.nome_empreendimento.localeCompare(b.nome_empreendimento)
            );
          break;
        case "1":
          this.requestdata = this.requestdata
            .slice()
            .sort((a, b) => new Date(b.data) - new Date(a.data));
          break;
        case "2":
          this.requestdata = this.requestdata
            .slice()
            .sort((a, b) => new Date(a.data) - new Date(b.data));
          break;
        default:
          break;
      }
    },
    setFilter() {
      const queryParams = new URLSearchParams();
      if (this.selectedSort !== "") queryParams.set("ordenacao", this.selectedSort);

      if (this.dataFilter.region !== "") queryParams.set("regiao_atuacao", this.dataFilter.region);
      if (this.dataFilter.state !== "") queryParams.set("regiao_estado", this.dataFilter.state);
      if (this.dataFilter.city !== "") queryParams.set("regiao_cidade", this.dataFilter.city);
      if (this.dataFilter.neighborhood !== "") queryParams.set("regiao_bairro", this.dataFilter.neighborhood);
      if (this.dataFilter.unitsSold.length > 0) queryParams.set("unidades_vendidas", this.dataFilter.unitsSold.join(","));
      if (this.dataFilter.activitySegmentation.length > 0) queryParams.set("empreendimento_segmento_atuacao", this.dataFilter.activitySegmentation.join(","));
      if (this.dataFilter.sizeEnterprise.length > 0) queryParams.set("porte_obra_tamanho", this.dataFilter.sizeEnterprise.join(","));
      if (this.dataFilter.generalProgress.length.length > 0) queryParams.set("obra_avanco_geral", this.dataFilter.generalProgress.join(","));
      if (this.dataFilter.numbersUnits.length > 0) queryParams.set("porte_obra_num_unidades", this.dataFilter.numbersUnits.join(","));
      if (this.dataFilter.typeEnterprise.length > 0) queryParams.set("empreendimento_tipo", this.dataFilter.typeEnterprise.join(","));
      if (this.dataFilter.phaseWork.length > 0) queryParams.set("obra_fase", this.dataFilter.phaseWork.join(","));

      return queryParams;
    },    
    handleFilter(valueFilter) {
      this.dataFilter = valueFilter;
      this.handleActiveTab(this.activeTab, true);
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  max-height: 100vh !important;
}

.surface-full {
  width: 100% !important;
  height: calc(100vh - 250px) !important;

  padding: 8px 16px;
  
  flex-direction: row !important;
  justify-content: flex-start !important;
  flex-wrap: wrap !important;
  align-content: flex-start !important;

  overflow-x: hidden !important;
  overflow-y: auto !important;
}

.content-enterprises {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 13px;

  .map {
    width: 100%;
  }

  .surface{
    width: 25%;
    height: calc(100vh - 250px);

    padding-right: 24px;
    
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    gap: 13px;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 0 8px 16px 0;

    @media screen and (max-width: 1800px) {
      width: 30%;
    }

    @media screen and (max-width: 1565px) {
      width: 35%;
    }

    @media screen and (max-width: 1460px) {
      width: 40%;
    }

    @media screen and (max-width: 1335px) {
      width: 50%;
    }

    @media screen and (max-width: 1150px) {
      width: 60%;
    }
  }

  @media screen and (max-width: 1023px) {
    flex-direction: column;
    
    .surface{
      width: calc(100vw - 80px);
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: flex-start;
      height: 400px;
      overflow-y: hidden;
      overflow-x: auto;
      padding: 0;
    }

    .map{
      height: calc(100vh - 670px);
    }
  }
}

.selectSort {
  width: 208px;
  height: 33px;
  display: flex;
  border-radius: 6px;
  border: solid 1px #adb5bd;
  background-color: #fff;
  font-size: 16px;
  text-align: left;
  color: #868e96;
}

.header-slot-template {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  margin-top: 13px;
}

.buttons-filter-ordering {
  width: 339px;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 16px;
  padding: 0;
}

.control-suface {
  display: flex;
  align-items: center;
  transition: all 0.4s ease-in-out;

  button {
    background-color: $white;
    color: $orange;
    border: 1px solid $neutral-50;
    padding: 9px;
    transition: all 0.4s ease-in-out;

    i {
      fill: $orange;
      transition: all 0.4s ease-in-out;
    }
  }

  :focus {
    outline: none;
    box-shadow: none;
  }

  .disabled {
    opacity: unset;
    border-color: $orange;
    transition: all 0.4s ease-in-out;
  }
}

.icon {
  display: inline-block;
  width: 13px;
  height: 13px;
}

/* Para navegadores Webkit (Chrome, Safari) */
.surface::-webkit-scrollbar {
  width: 6px;
  /* Largura da barra de rolagem */
}

.surface::-webkit-scrollbar-thumb {
  background-color: $neutral-50;
  /* Cor da barra de rolagem */
  border-radius: 3px;
  /* Borda arredondada da barra de rolagem */
}

.surface::-webkit-scrollbar-track {
  background-color: #f2f2f2;
  /* Cor do fundo da barra de rolagem */
}

/* Para navegadores Firefox */
.surface {
  scrollbar-width: thin;
  /* Largura da barra de rolagem */
}

.surface::-webkit-scrollbar-thumb {
  background-color: #888;
  /* Cor da barra de rolagem */
  border-radius: 4px;
  /* Borda arredondada da barra de rolagem */
}

.surface::-webkit-scrollbar-track {
  background-color: #f2f2f2;
  /* Cor do fundo da barra de rolagem */
}

.subtitle {
  font-size: 18px;
}


.tabs {
  display: flex;
  border-bottom: 1px solid $neutral-40;
}

.tab {
  background-color: transparent;
  padding: 10px 5px;
  cursor: pointer;
  border-radius: 5px 5px 0 0;
  margin-right: 20px;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
}

.tab.active {
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
  color: $blue-light;
  border-bottom: 2px solid $blue-light;
}

.tab-content {
  display: flex;
  overflow: hidden;
  width: 100%;
  margin-top: 13px;
}

.tab-pane {
  display: none;
}

.tab-pane.active {
  display: block;
}

.on-loading {
  width: 100%;
  height: 100%;
  z-index: 9999;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;

  /deep/.spinner-grow-sm {
    width: 4rem;
    height: 4rem;
  }
}

.no-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  width: 100%;
  height: 100%;

  button {
    width: 245px;
    height: 40px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 9px 14px;
    border-radius: 6px;
    background-color: #ed7554;
    border: none;
    color: #fff;
    text-decoration: none;
  }
}

.box-no-content {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 186px;
  height: 186px;
  border-radius: 100px;
  background-color: #fff;
  margin-bottom: 24px;
}

.icon-no-content {
  width: 72px;
  height: 96px;
  flex-grow: 0;
  z-index: 9999;
}

.title-no-content {
  height: fit-content;
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
  text-align: left;
  color: #343a40;
  margin-bottom: 8px;
}

.description-no-content {
  width: 345px;
  height: fit-content;
  margin-bottom: 24px;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
  text-align: center;
  color: #343a40;
}

.icon-button-search {
  width: 16px;
  height: 16px;
  flex-grow: 0;
  object-fit: contain;
  margin-right: 12px;
}
</style>
