<template>
  <div class="container-enterprises">
    <div v-if="visao_usuario === 'fundo_investimento'">
      <enterprises-found></enterprises-found>
    </div>
    <div v-if="visao_usuario === 'incorporadora'">
      <enterprises-merging></enterprises-merging>
    </div>
  </div>
</template>


<script>
import EnterprisesMerging from "@/views/enterprises/Merging.vue";
import EnterprisesFound from "@/views/enterprises/Found.vue";

export default {
  components: { EnterprisesMerging, EnterprisesFound },
  data() {
    return {
      visao_usuario: null,
    };
  },
  mounted() {
    const client = this.$router.app.$session.get("client");
    this.visao_usuario = client.visao_usuario;
  },
  methods: {
  },
};
</script>


<style lang="scss" scoped>
.content-enterprises {
  display: flex;
  width: 100%;
}
</style>
