<template>
  <div class="container-enterprises">
    <header-content title="Painel Meus Empreendimentos">
      <div class="d-flex justify-content-between">

        <div class="w-75">
          <p class="subtitle">
            Cadastre novos empreendimentos que demandam investimento.
          </p>
        </div>

        <div class="header-slot-template">
          <b-button
            v-model="modalShow"
            variant="primary"
            class="new-button"
            @click="openModal"
          >
            <img
              src="../../assets/Icon_Plus.svg"
              alt="Adicionar empreendimento"
              class="Vector-Stroke"
            />
            Novo empreendimento
          </b-button>
        </div>
      </div>
    </header-content>

    <div>
      <b-alert
        class="alert"
        variant="success"
        :show="dismissCountDown"
        @dismissed="dismissCountDown = 0"
        @dismiss-count-down="countDownChanged"
        >Seu novo empreendimento foi cadastrado com sucesso</b-alert
      >
      <div class="container-fluid">
        <div class="tabs-container">
          <div class="tabs">
            <div
              v-for="(tab, index) in tabs"
              :key="index"
              class="tab"
              :class="{ active: activeTab === index }"
              @click="handleActiveTab(index)"
            >
              {{ tab.title }}
            </div>
          </div>

          <div class="header-slot-template">
            <div class="control-suface">
              <b-button class="mr-2 d-flex align-items-center" :disabled="!surfaceFull" @click="toggleSurface">
                <img v-if="!surfaceFull" class="icon" src="../../assets/not-full.svg" alt="Icon Not Full" />
                <img v-else class="icon" src="../../assets/not-full-black.svg" alt="Icon Not Full" />
              </b-button>
              <b-button class="d-flex align-items-center" :disabled="surfaceFull" @click="toggleSurface">
                <img v-if="surfaceFull" class="icon" src="../../assets/full.svg" alt="Icon Full" />
                <img v-else class="icon" src="../../assets/full-black.svg" alt="Icon Full" />
              </b-button>
            </div>
            <div class="buttons-filter-ordering">
              <PainelDrop :show="showdrop" @emitShowDrop="handleShowDrop" @emitSendFilter="handleFilter" />
              <select v-model="selectedSort" @change="handleSelectedSort" class="selectSort">
                <option disabled value="" selected>Ordenar por</option>
                <option v-for="option in ordering" v-bind:value="option.value" :key="option.value">
                  {{ option.title }}
                </option>
              </select>
            </div>
          </div>

          <div v-if="isNoContent">
            <div class="no-content">
              <div class="box-no-content">
                <img src="../../assets/icon-emps.svg" class="icon-no-content" alt="Icone" />
              </div>
              <div class="title-no-content">
                <span>Ainda não há empreendimentos para investimento</span>
              </div>
              <div class="description-no-content">
                <span>Cadastre um novo empreendimento e deixe-o visível para os
                  Fundos de Investimento.</span>
              </div>
              <b-button v-model="modalShow" variant="primary" class="icon-button-search" @click="openModal">
                <img src="../../assets/Icon_Plus.svg" alt="Adicionar empreendimento" class="Vector-Stroke" />
                Novo empreendimento
              </b-button>
            </div>
          </div>
          <div v-else>
            <div class="tab-content">
              <div
                v-show="!isNoContent"
                class="content-enterprises"
                :class="{ 'content-full': surfaceFull }"
              >
                <div v-if="!surfaceFull" class="map">
                  <MapLeaflet
                    v-if="!onLoading"
                    :coordenades="coordinates"
                    :request="responseData"
                    :isminimap="true"
                  />
                  <MapLoading v-else />
                </div>
                <div class="surface" :class="{ 'surface-full': surfaceFull }">
                  <div
                    v-if="!onLoading"
                    v-for="(data, index) in responseData"
                    :key="index + isKey"
                  >
                    <card-emp :data="data" />
                  </div>
                  <div v-if="onLoading" v-for="index in 6" :key="index">
                    <CardLoading />
                  </div>
                </div>
              </div>
              <div v-if="isNoContent" class="no-content">
                <div class="box-no-content">
                  <img
                    :src="noContent.icon"
                    class="icon-no-content"
                    alt="Icone"
                  />
                </div>
                <div class="title-no-content">
                  <span>{{ noContent.title }}</span>
                </div>
                <div class="description-no-content">
                  <span>{{ noContent.description }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal
      v-model="modalShow"
      title="Novo Empreendimento para investimento"
      size="xl"
      centered
      cancel-title="Cancelar"
      ok-title="Concluir"
      @ok="saveEnterprise"
      :ok-disabled="isButtonDisabled"
      lazy
    >
      <b-form class="container-fluid p-0 form-user-content">
        <b-row>
          <b-col cols="12" md="12" lg="6" sm="12">
            <b-form-group
              label="Nome do empreendimento"
              label-for="empreendimentoNome"
              label-class="font-weight-bold"
            >
              <b-form-input
                id="empreendimentoNome"
                v-model="empreendimentoNome"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12" md="12" lg="6" sm="12">
            <b-form-group
              label="Tipo de empreendimento"
              label-for="tipoEmpreendimento"
              label-class="font-weight-bold"
            >
              <select
                id="tipoEmpreendimento"
                v-model="tipoEmpreendimento"
                class="form-control"
              >
                <option
                  v-for="option in typeEmpOptions"
                  :key="option.value"
                  :value="option.value"
                >
                  {{ option.label }}
                </option>
              </select>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" md="12" lg="2" sm="12">
            <b-form-group
              label="Segmento"
              label-for="segmento"
              label-class="font-weight-bold"
            >
              <select id="segmento" v-model="segmento" class="form-control">
                <option v-for="option in typeSegments" :key="option.value" :value="option.value">
                  {{ option.label }}
                </option>
              </select>
            </b-form-group>
          </b-col>
          <b-col cols="12" md="12" lg="2" sm="12">
            <b-form-group
              label="Custo Estimado (R$)"
              label-for="custoEstimado"
              label-class="font-weight-bold"
            >
              <b-form-input
                id="custoEstimado"
                v-model="custoEstimado"
                type="text"
                class="form-control"
                @input="updateAverageCost"
                v-numeric-only
              />
            </b-form-group>
          </b-col>
          <b-col cols="12" md="12" lg="2" sm="12">
            <b-form-group
              label="VGV (R$)"
              label-for="vgv"
              label-class="font-weight-bold"
            >
              <b-form-input
                id="vgv"
                v-model="vgv"
                type="text"
                class="form-control"
                @input="updateVgvValue"
                v-numeric-only
              />
            </b-form-group>
          </b-col>
          <b-col cols="12" md="12" lg="3" sm="12">
            <b-form-group
              label="Fase de Obra"
              label-for="faseObra"
              label-class="font-weight-bold"
            >
              <select id="faseObra" v-model="faseObra" class="form-control">
                <option v-for="option in constructionPhaseOptions" :key="option.value" :value="option.value">
                  {{ option.label }}
                </option>
              </select>
            </b-form-group>
          </b-col>
          <b-col cols="12" md="12" lg="3" sm="12">
            <b-form-group
              label="Avanço Geral da Obra (%)"
              label-for="avancoObra"
              label-class="font-weight-bold"
            >
              <b-form-input
                id="avancoObra"
                v-model="avancoObra"
                type="text"
                class="form-control"
                @input="updateGeneralProgress"
                v-numeric-only
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" md="12" lg="2" sm="12">
            <b-form-group
              label="N° de Unidades"
              label-for="numUnidades"
              label-class="font-weight-bold"
            >
              <b-form-input
                id="numUnidades"
                v-model="numUnidades"
                type="text"
                class="form-control"
                v-numeric-only
              />
            </b-form-group>
          </b-col>
          <b-col cols="12" md="12" lg="2" sm="12">
            <b-form-group
              label="Área das Unidades (m²)"
              label-for="areaUnidades"
              label-class="font-weight-bold"
            >
              <select
                id="areaUnidades"
                v-model="areaUnidades"
                class="form-control"
              >
                <option
                  v-for="option in areaUnitsOptions"
                  :key="option.value"
                  :value="option.value"
                >
                  {{ option.label }}
                </option>
              </select>
            </b-form-group>
          </b-col>
          <b-col cols="12" md="12" lg="2" sm="12">
            <b-form-group
              label="Ticket Médio (R$)"
              label-for="ticketMedio"
              label-class="font-weight-bold"
            >
              <b-form-input
                id="ticketMedio"
                v-model="ticketMedio"
                type="text"
                @input="updateAverageTicket"
                v-numeric-only
              />
            </b-form-group>
          </b-col>
          <b-col cols="12" md="12" lg="3" sm="12">
            <b-form-group
              label="Unidades Vendidas"
              label-for="unidadesVendidas"
              label-class="font-weight-bold"
            >
              <b-form-input
                id="unidadesVendidas"
                v-model="unidadesVendidas"
                type="text"
                v-numeric-only
              />
            </b-form-group>
          </b-col>
          <b-col cols="12" md="12" lg="3" sm="12">
            <b-form-group
              label="Estoque"
              label-for="estoque"
              label-class="font-weight-bold"
            >
              <b-form-input
                id="estoque"
                v-model="estoque"
                type="text"
                v-numeric-only
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" md="12" lg="4" sm="12">
            <b-form-group
              label="Endereço"
              label-for="endereco"
              label-class="font-weight-bold"
            >
              <b-form-input
                type="text"
                id="autocomplete"
                name="autocomplete"
                ref="inputSearch"
                placeholder="Informe o endereço"
                v-model="endereco"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12" md="12" lg="2" sm="12">
            <b-form-group
              label="Estado"
              label-for="estado"
              label-class="font-weight-bold"
            >
              <b-form-input
                type="text"
                id="estado"
                v-model="estado"
                class="form-control"
                :key="isKey"
                :disabled="disabled && estado !== ''"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12" md="12" lg="3" sm="12">
            <b-form-group
              label="Cidade"
              label-for="cidade"
              label-class="font-weight-bold"
            >
              <b-form-input
                type="text"
                id="cidade"
                v-model="cidade"
                class="form-control"
                :key="isKey"
                :disabled="disabled && cidade !== ''"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12" md="12" lg="3" sm="12">
            <b-form-group
              label="Bairro"
              label-for="bairro"
              label-class="font-weight-bold"
            >
              <b-form-input
                :disabled="disabled && bairro !== ''"
                id="bairro"
                v-model="bairro"
                :key="isKey"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" md="12" lg="6" sm="12">
            <b-form-group
              label="Imagens do Empreendimento"
              label-for="imagensEmpreendimento"
              label-class="font-weight-bold"
            >
              <div class="icon-upload">
                <label for="imagensEmpreendimento" class="upload-label">
                  <img src="../../assets/Icon_Upload.svg" alt="Upload Icon" class="upload-icon" />
                  <span class="text-icon-upload">
                    Arraste as imagens aqui ou clique para selecionar
                  </span>
                </label>
                <b-form-file id="imagensEmpreendimento" v-model="imagensEmpreendimento" placeholder=" "
                  drop-placeholder=" " multiple class="file-browse" @change="handleImageUpload" accept="image/*" />
              </div>
            </b-form-group>
          </b-col>
          <b-col cols="12" md="12" lg="6" sm="12">
            <b-form-group
              label="Documentos do Empreendimento"
              label-for="documentosEmpreendimento"
              label-class="font-weight-bold"
            >
              <div class="icon-upload">
                <label for="documentosEmpreendimento" class="upload-label">
                  <img src="../../assets/Icon_Upload.svg" alt="Upload Icon" class="upload-icon" />
                  <span class="text-icon-upload">
                    Arraste os documentos aqui ou clique para selecionar
                  </span>
                </label>
                <b-form-file id="documentosEmpreendimento" v-model="documentosEmpreendimento" placeholder=" "
                  drop-placeholder=" " multiple class="file-browse" @change="handleDocumentUpload" accept=".pdf" />
              </div>
            </b-form-group>
          </b-col>
        </b-row>
        <div  class="miniatura-container">
          <div class="miniaturas-imagens-documentos">
          <div
            v-for="(imagem, index) in imagens"
            :key="index"
            class="miniatura imagem"
          >
            <img :src="imagem.url" alt="Miniatura da Imagem" />
            <div class="file-name">
              {{ imagem.name }}
            </div>
            <button class="btn-delete" @click.prevent="deleteImage(index)">
              <img src="../../assets/icon-trash.svg" alt="Excluir" />
            </button>
          </div>
        </div>

        <div class="miniaturas-imagens-documentos">
          <div
            v-for="(documento, index) in documentos"
            :key="index"
            class="miniatura documento"
          >
            <img src="../../assets/icon-file.svg" alt="Ícone de Documento" />
            <div class="file-name">
              {{ extractFileName(documento.name) }}
            </div>
            <button class="btn-delete" @click.prevent="deleteDocument(index)">
              <img src="../../assets/icon-trash.svg" alt="Excluir" />
            </button>
          </div>
        </div>
        </div>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import HeaderContent from "@/components/Header.vue";
import CardEmp from "@/components/CardEmp.vue";
import MapLeaflet from "@/components/MapVueLeaflet.vue";
import data from "../../dataMockup/dataMockup.json";
import ImgInterests from "../../assets/icon-building-check.svg";
import ImgSaved from "../../assets/icon-bookmark.svg";
import PainelDrop from "@/components/PainelDropdown.vue";
import APIVitrine from "@/server/APIVitrine";
import axios from "axios";
import MapLoading from "@/components/loading/Map.vue";
import CardLoading from "@/components/loading/Card.vue";

import Formatters from "@/utils/Formatters.js";

export default {
  components: {
    HeaderContent,
    CardEmp,
    MapLeaflet,
    PainelDrop,
    MapLoading,
    CardLoading,
  },
  data() {
    return {
      dismissSecs: 5,
      dismissCountDown: 0,
      disabled: false,
      isKey: 0,
      autocomplete: null,
      place: null,
      lat: null,
      lng: null,
      address: "",
      address_name: "",
      mockupdata: data,
      responseData: null,
      surfaceFull: false,
      showdrop: false,
      onLoading: false,
      isNoContent: false,
      noContent: null,
      activeTab: 0,
      coordinates: [],
      tabs: [],
      imagensEmpreendimento: [],
      documentosEmpreendimento: [],
      imagens: [],
      documentos: [],
      isButtonDisabled: true,
      selectedSort: "",
      dataFilter: {
        region: "",
        state: "",
        city: "",
        neighborhood: "",
        typeEnterprise: [],
        activitySegmentation: [],
        sizeEnterprise: [],
        numbersUnits: [],
        unitsSold: [],
        phaseWork: [],
        generalProgress: [],
      },      
      content_noContent: [
        {
          icon: ImgInterests,
          title: "Ainda não há empreendimentos que aceitaram o seu interesse",
          description:
            "Após a Incorporadora aceitar o seu interesse, o empreendimento irá aparecer aqui.",
        },
        {
          icon: ImgInterests,
          title: "Ainda não há empreendimentos em que demostrei interesse",
          description:
            "Busque empreendimentos e demostre interesse naqueles que você deseja investir.",
        },
        {
          icon: ImgSaved,
          title: "Ainda não há empreendimentos salvos",
          description:
            "Busque empreendimentos e salve-os para consultas futuras.",
        },
      ],
      isFundoInvestimento: false, // aqui a tela para fundo de investimento
      isNotEnterprise: true, // aqui muda a tela se possui empreendimento para investir
      modalShow: false,
      typeEmpOptions: [
        { label: "Residencial Vertical", value: "Residencial Vertical" },
        { label: "Residencial Horizontal", value: "Residencial Horizontal" },
      ],
      typeSegments: [
        { value: "special", label: "Especial (1 Dorm. até 40m²)" },
        { value: "economy", label: "Econômico (até R$ 264mil)" },
        { value: "standard", label: "Standard (R$ 264mil - R$ 500mil)" },
        { value: "medium", label: "Médio (R$ 500mil - R$ 1M)" },
        { value: "high", label: "Alto (R$ 1M - R$ 1.5M)" },
        { value: "lux", label: "Luxo (R$ 1.5M - R$ 3M)" },
        { value: "highlux", label: "Super Luxo (R$ 3M+)" },
      ],
      constructionPhaseOptions: [
        { label: "Busca de terreno", value: "busca-de-terreno" },
        { label: "Preparação", value: "preparacao" },
        { label: "Terraplanagem", value: "terraplanagem" },
        { label: "Escavação", value: "escavacao" },
        { label: "Fundação", value: "fundacao" },
        { label: "Estrutura", value: "estrutura" },
        { label: "Alvenaria", value: "alvenaria" },
        { label: "Acabamento", value: "acabamento" },
        { label: "Entrega", value: "entrega" },
      ],
      // generalProgressOfWorkOptions: [{ label: "Opção 1", value: "option1" }],
      areaUnitsOptions: [
        { label: "0-40", value: "0-40m" },
        { label: "40-60", value: "40-60m" },
        { label: "60-80", value: "60-80m" },
        { label: "80-100", value: "80-100m" },
        { label: "100-130", value: "100-130m" },
        { label: "130-180", value: "130-180m" },
        { label: "250+", value: "250m²+" },
      ],
      stateOptions: [
        { label: "Rio de Janeiro", value: "Rio de Janeiro" },
        { label: "São Paulo", value: "São Paulo" },
      ],
      cityOptions: [
        { label: "São João de Meriti", value: "São João de Meriti" },
        { label: "Araraquara", value: "Araraquara" },
      ],
      imagesEnterprise: [],
      documentsEnterprise: [],
      empreendimentoNome: "",
      tipoEmpreendimento: "",
      segmento: "",
      custoEstimado: "",
      vgv: "",
      faseObra: "",
      avancoObra: "",
      numUnidades: "",
      areaUnidades: "",
      ticketMedio: "",
      unidadesVendidas: "",
      estoque: "",
      endereco: "",
      estado: "",
      cidade: "",
      bairro: "",
      isRequiredMessages: {
        empreendimentoNome: "",
        tipoEmpreendimento: "",
        segmento: "",
        custoEstimado: "",
        vgv: "",
        faseObra: "",
        avancoObra: "",
        numUnidades: "",
        areaUnidades: "",
        ticketMedio: "",
        unidadesVendidas: "",
        estoque: "",
        endereco: "",
        estado: "",
        cidade: "",
        bairro: "",
      },
      ordering: [
        { title: "Alfabética", value: 0 },
        { title: "Mais recente", value: 1 },
        { title: "Menos recente", value: 2 },
      ],      
    };
  },
  directives: {
    'numeric-only': {
      bind(el) {
        el.addEventListener('keydown', (e) => {
          if (!/[0-9]/.test(e.key) && e.key !== 'Backspace' && e.key !== 'Tab' && e.key !== 'ArrowLeft' && e.key !== 'ArrowRight') {
            e.preventDefault();
          }
        });
      }
    }
  },
  watch: {
    empreendimentoNome() {
      this.validateForm();
    },
    tipoEmpreendimento() {
      this.validateForm();
    },
    segmento() {
      this.validateForm();
    },
    custoEstimado() {
      this.validateForm();
    },
    vgv() {
      this.validateForm();
    },
    faseObra() {
      this.validateForm();
    },
    avancoObra() {
      this.validateForm();
    },
    numUnidades() {
      this.validateForm();
    },
    areaUnidades() {
      this.validateForm();
    },
    ticketMedio() {
      this.validateForm();
    },
    unidadesVendidas() {
      this.validateForm();
    },
    estoque() {
      this.validateForm();
    },
    endereco() {
      this.validateForm();
    },
    estado() {
      this.validateForm();
    },
    cidade() {
      this.validateForm();
    },
    bairro() {
      this.validateForm();
    },
  },
  async mounted() {
    this.setupTabs();
    this.getEnterpriseData();

    if (this.$route.query.tabIndex) {
      const tab = parseInt(this.$route.query.tabIndex);
      this.handleActiveTab(tab);
    }
  },
  methods: {
    updateGeneralProgress(value) {
      const valueWithoutString = value.replaceAll(/\D/g, "");
      this.avancoObra = Formatters.formaterPercentage(valueWithoutString);
    },
    updateAverageCost(value) {
      const valueWithOutString = value.replaceAll(/\D/g, "")
      this.custoEstimado = Formatters.formatMoney(valueWithOutString);
    },
    updateVgvValue(value) {
      const valueWithOutString = value.replaceAll(/\D/g, "")
      this.vgv = Formatters.formatMoney(valueWithOutString);
    },
    updateAverageTicket(value) {
      const valueWithOutString = value.replaceAll(/\D/g, "")
      this.ticketMedio = Formatters.formatMoney(valueWithOutString);
    },
    handleShowDrop(value) {
      this.showdrop = value;
    },    
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
    },
    showAlert() {
      this.dismissCountDown = this.dismissSecs;
    },
    loadGoogleMaps() {
      return new Promise((resolve) => {
        if (typeof google === "undefined") {
          var script = document.createElement("script");
          script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.VUE_APP_API_KEY}&libraries=places`;
          script.onload = resolve;
          document.head.appendChild(script);
        } else {
          resolve();
        }
      });
    },
    async initAutocomplete() {
      const input = document.getElementById("autocomplete");
      const options = {
        componentRestrictions: { country: "BR" },
        types: ["address"],
        fields: ["geometry", "formatted_address", "address_components"],
      };

      this.autocomplete = await new google.maps.places.Autocomplete(
        input,
        options
      );
      // Lidar com o evento de seleção
      this.autocomplete.addListener("place_changed", () => {
        this.place = this.autocomplete.getPlace();
        this.lat = this.place.geometry.location.lat();
        this.lng = this.place.geometry.location.lng();
        this.endereco = this.place.formatted_address;
        let state = "";
        let city = "";
        let neighborhood = "";

        this.place.address_components.forEach((component) => {

          if (component.types.includes("administrative_area_level_1")) {
            state = component.short_name;
          } else if (component.types.includes("administrative_area_level_2")) {
            city = component.long_name;
          } else if (component.types.includes("sublocality")) {
            neighborhood = component.long_name;
          } else if (
            neighborhood == "" &&
            component.types.includes("administrative_area_level_4")
          ) {
            neighborhood = component.long_name;
          }
        });

        this.endereco = this.place.formatted_address;
        this.estado = state;
        this.cidade = city;
        this.bairro = neighborhood;
        this.disabled = true;
        this.isKey++;
      });
    },
    handleFilter(valueFilter) {
      this.dataFilter = valueFilter;
      this.handleActiveTab(this.activeTab, true);
    },
    handleActiveTab(value, filtered = false) {
      const tabFunctions = [
        this.getEnterpriseData,
      ];

      if (filtered || (value !== this.activeTab && tabFunctions[value])) {
        tabFunctions[value]();
        this.activeTab = value;
      }
    },    
    handleSelectedSort() {
      switch (this.selectedSort) {
        case "0":
          this.requestdata = this.requestdata
            .slice()
            .sort((a, b) =>
              a.nome_empreendimento.localeCompare(b.nome_empreendimento)
            );
          break;
        case "1":
          this.requestdata = this.requestdata
            .slice()
            .sort((a, b) => new Date(b.data) - new Date(a.data));
          break;
        case "2":
          this.requestdata = this.requestdata
            .slice()
            .sort((a, b) => new Date(a.data) - new Date(b.data));
          break;
        default:
          break;
      }
    },
    setFilter() {
      const queryParams = new URLSearchParams();
      if (this.selectedSort !== "") queryParams.set("ordenacao", this.selectedSort);

      if (this.dataFilter.region !== "") queryParams.set("regiao_atuacao", this.dataFilter.region);
      if (this.dataFilter.state !== "") queryParams.set("regiao_estado", this.dataFilter.state);
      if (this.dataFilter.city !== "") queryParams.set("regiao_cidade", this.dataFilter.city);
      if (this.dataFilter.neighborhood !== "") queryParams.set("regiao_bairro", this.dataFilter.neighborhood);
      if (this.dataFilter.unitsSold.length > 0) queryParams.set("unidades_vendidas", this.dataFilter.unitsSold.join(","));
      if (this.dataFilter.activitySegmentation.length > 0) queryParams.set("empreendimento_segmento_atuacao", this.dataFilter.activitySegmentation.join(","));
      if (this.dataFilter.sizeEnterprise.length > 0) queryParams.set("porte_obra_tamanho", this.dataFilter.sizeEnterprise.join(","));
      if (this.dataFilter.generalProgress.length.length > 0) queryParams.set("obra_avanco_geral", this.dataFilter.generalProgress.join(","));
      if (this.dataFilter.numbersUnits.length > 0) queryParams.set("porte_obra_num_unidades", this.dataFilter.numbersUnits.join(","));
      if (this.dataFilter.typeEnterprise.length > 0) queryParams.set("empreendimento_tipo", this.dataFilter.typeEnterprise.join(","));
      if (this.dataFilter.phaseWork.length > 0) queryParams.set("obra_fase", this.dataFilter.phaseWork.join(","));

      return queryParams;
    },        
    handleValidateFirstStepForm(items) {
      Object.keys(this.isRequiredMessages).forEach((key) => {
        if (!items.tipo_empreendimento[key]) {
          this.isRequiredMessages[key] = "Campo obrigatório";
        } else {
          this.isRequiredMessages[key] = "";
        }
      });

      // if (!Validators.validateEmail(this.dataRegister.dados_gerais.email)) {
      //   this.isRequiredMessages.email = "E-mail inválido";
      // }

      // if (!Validators.validateCnpj(this.dataRegister.dados_gerais.cnpj)) {
      //   this.isRequiredMessages.cnpj = "CNPJ inválido";
      // }

      return Object.values(this.isRequiredMessages).every(
        (value) => value === ""
      );
    },
    async saveEnterprise(e) {
      e.preventDefault();
      this.isButtonDisabled = true;
      this.isKey++;
      const enterprise = {
        empreendimento: {
          nome_empreendimento: this.empreendimentoNome,
          tipo_empreendimento: [this.tipoEmpreendimento],
          segmento_atuacao: [this.segmento],
          custo_estimado_reais:  parseFloat(this.custoEstimado.replaceAll(/\D/g, "")) / 100,
          vgv_reais: parseFloat(this.vgv.replaceAll(/\D/g, "")) / 100,
          fase_obra: [this.faseObra],
          avanco_geral_obra: parseFloat(this.avancoObra.replaceAll(/\D/g, "")),
          numero_unidades: parseInt(this.numUnidades),
          area_unidades_m2: [this.areaUnidades],
          ticket_medio: parseFloat(this.ticketMedio.replaceAll(/\D/g, "")) / 100,
          unidades_vendidas: parseInt(this.unidadesVendidas),
          estoque: parseInt(this.estoque),
          endereco: this.endereco,
          regiao: this.getRegion(this.estado),
          estado: this.estado,
          cidade: this.cidade,
          bairro: this.bairro,
          imagens: this.imagesEnterprise,
          documentos: this.documentsEnterprise,
          coordenadas: {
            latitude: this.lat,
            longitude: this.lng,
          },
          estatisticas: {
            salvos: [],
            interesses: [],
            aceitos: [],
            recusados: [],
            visualizados: [],
          },
          situacao: "ATIVO",
        },
      };
      try {
        const id_client = this.$session.get("master").id_cliente;
        await axios.post(
          "/api/vitrine/incorporadora/empreendimentos",
          enterprise,
          {
            headers: {
              id_cliente: id_client,
            },
          }
        );
        const responseEnterprises = await axios.get(
          `/api/vitrine/incorporadora/empreendimentos`,
          { headers: { id_cliente: id_client } }
        );
        console.log("Empreendimentos created", responseEnterprises);

        this.responseData = responseEnterprises.data.empreendimentos;
        this.showAlert();
        this.clearFormFields();
        this.modalShow = false;
        this.isKey++;
        this.isButtonDisabled = false;

        this.getEnterpriseData();
      } catch (error) {
        console.error("Erro ao enviar para a API:", error);
      }
    },
    clearFormFields() {
      this.empreendimentoNome = "";
      this.tipoEmpreendimento = "";
      this.segmento = "";
      this.custoEstimado = "";
      this.vgv = "";
      this.faseObra = "";
      this.avancoObra = "";
      this.numUnidades = "";
      this.areaUnidades = "";
      this.ticketMedio = "";
      this.unidadesVendidas = "";
      this.estoque = "";
      this.endereco = "";
      this.regiao = "";
      this.estado = "";
      this.cidade = "";
      this.bairro = "";
      this.imagesEnterprise = [];
      this.documentsEnterprise = [];
      this.imagens = [];
      this.documentos = [];
    },
    validateForm() {
      this.isButtonDisabled =
        !this.empreendimentoNome ||
        !this.tipoEmpreendimento ||
        !this.segmento ||
        !this.custoEstimado ||
        !this.vgv ||
        !this.faseObra ||
        !this.avancoObra ||
        !this.numUnidades ||
        !this.areaUnidades ||
        !this.ticketMedio ||
        !this.unidadesVendidas ||
        !this.estoque ||
        !this.endereco ||
        !this.estado ||
        !this.cidade ||
        !this.bairro;
    },
    async handleImageUpload(event) {
      //TODO: Criar metodo na API que salva mais de uma imagem por vez
      //TODO: Criar metodo para deletar upload do Firebase
      const files = event.target.files;
      for (let i = 0; i < files.length; i++) {
        const file = files[i];

        if (!file.type.startsWith("image/")) {
          console.warn(
            `${file.name} não é um arquivo de imagem. Será ignorado.`
          );
          continue;
        }

        this.imagens.push({
          url: URL.createObjectURL(file),
          name: file.name,
        });

        const response = await APIVitrine.uploadFile(file);

        const formattedResponse = {
          titulo: response.file,
          descricao: response.mimetype,
          link: response.url,
        };

        this.imagesEnterprise.push(formattedResponse);
      }
    },
    async handleDocumentUpload(event) {
      const files = event.target.files;
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        if (file.type.startsWith("application/pdf")) {
          this.documentos.push({
            url: URL.createObjectURL(file),
            name: file.name,
          });
        } else {
          console.warn(
            `${file.name} não é um arquivo do tipo PDF. Será ignorado.`
          );
          continue;
        }

        const response = await APIVitrine.uploadFile(file);

        const formattedResponse = {
          titulo: response.file,
          descricao: response.mimetype,
          link: response.url,
        };

        this.documentsEnterprise.push(formattedResponse);
      }
    },
    deleteImage(index) {
      this.imagens.splice(index, 1);
      this.imagesEnterprise.splice(index, 1);
    },
    deleteDocument(index) {
      this.documentos.splice(index, 1);
      this.documentsEnterprise.splice(index, 1);
    },
    extractFileName(path) {
      return path.split("/").pop().split("?")[0];
    },
    setupTabs() {
      if (this.isFundoInvestimento) {
        this.tabs = [
          { title: "Aceitos" },
          { title: "Meus interesses" },
          { title: "Salvos" },
        ];
      } else {
        this.tabs = [{ title: "Para investimento" }];
      }
    },
    toggleSurface() {
      this.surfaceFull = !this.surfaceFull;
    },
    async openModal() {
      this.modalShow = true;
      await this.loadGoogleMaps().then(() => {
        this.initAutocomplete();
      });
    },
    async getEnterpriseData() {
      this.onLoading = true;
      this.isNoContent = false;
      try {
        const master = this.$session.get("master");
        const enterprises = await axios.get(
          `/api/vitrine/incorporadora/empreendimentos?${this.setFilter().toString()}`,
          { headers: { id_cliente: master.id_cliente } }
        );
        if (enterprises.data.empreendimentos.length === 0) {
          this.onLoading = false;
          this.isNoContent = true;
          this.noContent = this.content_noContent[this.activeTab];
        } else {
          this.responseData = enterprises.data.empreendimentos;
          this.onLoading = false;
          this.isNoContent = false;
          this.noContent = [];
          this.coordinates = [];
          this.responseData.forEach((item) => {
            this.coordinates.push({
              _id: item._id,
              latitude: item.empreendimento.coordenadas.latitude,
              longitude: item.empreendimento.coordenadas.longitude,
            });
          });
        }
      } catch (e) {
        console.error(
          "Erro ao fazer requisição dos meus empreendimentos:",
          error
        );
      }
    },
    getRegion(estado) {
      const estadosPorRegiao = [
        { regiao: "norte", estado: "AC" },
        { regiao: "norte", estado: "AP" },
        { regiao: "norte", estado: "AM" },
        { regiao: "norte", estado: "PA" },
        { regiao: "norte", estado: "RO" },
        { regiao: "norte", estado: "RR" },
        { regiao: "norte", estado: "TO" },
        { regiao: "nordeste", estado: "AL" },
        { regiao: "nordeste", estado: "BA" },
        { regiao: "nordeste", estado: "CE" },
        { regiao: "nordeste", estado: "MA" },
        { regiao: "nordeste", estado: "PB" },
        { regiao: "nordeste", estado: "PE" },
        { regiao: "nordeste", estado: "PI" },
        { regiao: "nordeste", estado: "RN" },
        { regiao: "nordeste", estado: "SE" },
        { regiao: "centro-oeste", estado: "DF" },
        { regiao: "centro-oeste", estado: "GO" },
        { regiao: "centro-oeste", estado: "MT" },
        { regiao: "centro-oeste", estado: "MS" },
        { regiao: "sudeste", estado: "ES" },
        { regiao: "sudeste", estado: "MG" },
        { regiao: "sudeste", estado: "RJ" },
        { regiao: "sudeste", estado: "SP" },
        { regiao: "sul", estado: "PR" },
        { regiao: "sul", estado: "RS" },
        { regiao: "sul", estado: "SC" },
      ]

      const selected = estadosPorRegiao.filter(
        (e) => e.estado.toLowerCase() === estado.toLowerCase()
      );

      return (selected[0] ? selected[0].regiao : "");
    }
  },
};
</script>

<style lang="scss" scoped>
.container-fluid {
  max-height: 100vh !important;
  padding: 0 !important;
}

.alert {
  position: absolute;
  right: 0;
  margin-right: 11px;
  padding: 15px 42px;
}

.surface-full {
  width: 100% !important;
  height: calc(100vh - 250px) !important;

  padding: 8px 16px;

  flex-direction: row !important;
  justify-content: flex-start !important;
  flex-wrap: wrap !important;
  align-content: flex-start !important;

  overflow-x: hidden !important;
  overflow-y: auto !important;
}

.form-user-content {
  max-height: calc(100vh - 300px) !important;
  overflow-y: auto !important;
  overflow-x: hidden !important;
}

.content-enterprises {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 13px;

  .map {
    width: 100%;
  }

  .surface {
    width: 25%;
    height: calc(100vh - 250px);

    padding-right: 24px;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    gap: 13px;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 0 8px 16px 0;

    @media screen and (max-width: 1800px) {
      width: 30%;
    }

    @media screen and (max-width: 1565px) {
      width: 35%;
    }

    @media screen and (max-width: 1460px) {
      width: 40%;
    }

    @media screen and (max-width: 1335px) {
      width: 50%;
    }

    @media screen and (max-width: 1150px) {
      width: 60%;
    }
  }

  @media screen and (max-width: 1023px) {
    flex-direction: column;

    .surface {
      width: calc(100vw - 80px);
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: flex-start;
      height: 400px;
      overflow-y: hidden;
      overflow-x: auto;
      padding: 0 8px;
    }

    .map {
      height: calc(100vh - 670px);
    }
  }
}

.buttons-filter-ordering {
  width: 339px;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 16px;
  padding: 0;
}

.control-suface {
  display: flex;
  align-items: center;
  transition: all 0.4s ease-in-out;

  button {
    background-color: $white;
    color: $orange;
    border: 1px solid $neutral-50;
    padding: 9px;
    transition: all 0.4s ease-in-out;

    i {
      fill: $orange;
      transition: all 0.4s ease-in-out;
    }
  }

  :focus {
    outline: none;
    box-shadow: none;
  }

  .disabled {
    opacity: unset;
    border-color: $orange;
    transition: all 0.4s ease-in-out;
  }
}

.icon {
  display: inline-block;
  width: 13px;
  height: 13px;
}

.content-enterprises {
  display: flex;
  width: 100%;
}

.header-slot-template {
  display: flex;
  justify-content: space-between;
  // width: fit-content;
  height: 100%;
  margin-top: 13px;
}

.selectSort {
  width: 208px;
  height: 33px;
  display: flex;
  border-radius: 6px;
  border: solid 1px #adb5bd;
  background-color: #fff;
  font-size: 16px;
  text-align: left;
  color: #868e96;
}

.control-suface {
  display: flex;
  align-items: center;
  transition: all 0.4s ease-in-out;

  button {
    background-color: $white;
    color: $orange;
    border: 1px solid $neutral-50;
    padding: 9px;
    transition: all 0.4s ease-in-out;

    i {
      fill: $orange;
      transition: all 0.4s ease-in-out;
    }
  }

  :focus {
    outline: none;
    box-shadow: none;
  }

  .disabled {
    opacity: unset;
    border-color: $orange;
    transition: all 0.4s ease-in-out;
  }
}

.icon {
  display: inline-block;
  width: 13px;
  height: 13px;
}

/* Para navegadores Webkit (Chrome, Safari) */
.surface::-webkit-scrollbar {
  width: 6px;
  /* Largura da barra de rolagem */
}

.surface::-webkit-scrollbar-thumb {
  background-color: $neutral-50;
  /* Cor da barra de rolagem */
  border-radius: 3px;
  /* Borda arredondada da barra de rolagem */
}

.surface::-webkit-scrollbar-track {
  background-color: #f2f2f2;
  /* Cor do fundo da barra de rolagem */
}

/* Para navegadores Firefox */
.surface {
  scrollbar-width: thin;
  /* Largura da barra de rolagem */
}

.surface::-webkit-scrollbar-thumb {
  background-color: #888;
  /* Cor da barra de rolagem */
  border-radius: 4px;
  /* Borda arredondada da barra de rolagem */
}

.surface::-webkit-scrollbar-track {
  background-color: #f2f2f2;
  /* Cor do fundo da barra de rolagem */
}

.tabs {
  display: flex;
  border-bottom: 1px solid $neutral-40;
}

.tab {
  background-color: transparent;
  padding: 10px 5px;
  cursor: pointer;
  border-radius: 5px 5px 0 0;
  margin-right: 20px;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
}

.tab.active {
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
  color: $blue-light;
  border-bottom: 2px solid $blue-light;
}

.tab-content {
  display: flex;
  overflow: hidden;
  width: 100%;
  margin-top: 14px;
}

.on-loading {
  width: 100%;
  height: 100%;
  z-index: 9999;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;

  /deep/.spinner-grow-sm {
    width: 4rem;
    height: 4rem;
  }
}

.no-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  width: 100%;
  height: 100%;

  button {
    width: 245px;
    height: 40px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 9px 15px;
    border-radius: 6px;
    background-color: #ed7554;
    border: none;
    color: #fff;
    text-decoration: none;
  }
}

.box-no-content {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 186px;
  height: 186px;
  border-radius: 100px;
  background-color: #fff;
  margin-bottom: 24px;
}

.icon-no-content {
  width: 72px;
  height: 96px;
  flex-grow: 0;
  z-index: 9999;
}

.title-no-content {
  height: fit-content;
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
  text-align: left;
  color: #343a40;
  margin-bottom: 8px;
}

.description-no-content {
  width: 345px;
  height: fit-content;
  margin-bottom: 24px;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
  text-align: center;
  color: #343a40;
}

.icon-button-search {
  width: 16px;
  height: 16px;
  flex-grow: 0;
  object-fit: contain;
  margin-right: 12px;
}

/* Para navegadores Webkit (Chrome, Safari) */
.surface::-webkit-scrollbar {
  width: 6px;
  /* Largura da barra de rolagem */
}

.surface::-webkit-scrollbar-thumb {
  background-color: $neutral-50;
  /* Cor da barra de rolagem */
  border-radius: 3px;
  /* Borda arredondada da barra de rolagem */
}

.surface::-webkit-scrollbar-track {
  background-color: #f2f2f2;
  /* Cor do fundo da barra de rolagem */
}

/* Para navegadores Firefox */
.surface {
  scrollbar-width: thin;
  /* Largura da barra de rolagem */
}

.surface::-webkit-scrollbar-thumb {
  background-color: #888;
  /* Cor da barra de rolagem */
  border-radius: 4px;
  /* Borda arredondada da barra de rolagem */
}

.surface::-webkit-scrollbar-track {
  background-color: #f2f2f2;
  /* Cor do fundo da barra de rolagem */
}

.subtitle {
  font-size: 18px;
}

.b-form-group label {
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.b-form-input,
.b-form-select,
.b-form-file {
  border-radius: 0.25rem;
  border: 1px solid #ced4da;
}

.b-form-file {
  background-color: #e9ecef;
  border: 1px dashed #adb5bd;
}

.b-form-file .custom-file-label {
  text-align: left;
}

.b-button:not(:last-child) {
  margin-right: 0.5rem;
}

.upload-icon {
  margin-right: 10px;
  font-size: 13px;
}

.icon-upload .text-icon-upload {
  font-size: 13px;
}

.upload-label {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.file-browse {
  opacity: 0;
  width: 0.1px;
  height: 0.1px;
  position: absolute;
  overflow: hidden;
  z-index: -1;
}

.text-icon-upload {
  font-size: 12px;
  width: 337px;
  height: 22px;
  font-family: SourceSansPro, sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.4;
  color: #212529;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.icon-upload {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
  border-radius: 5px;
  border: solid 1px #868e96;
  background-color: #f8f9fa;
  flex-direction: column;
  padding: 0 20px;
}

/* Container para as miniaturas */
.miniatura-container {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: flex-start;
  justify-content: space-between;
  padding: 10px 0;
}

.miniaturas-imagens-documentos {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: flex-start;
  padding: 10px 0;
}

.miniatura {
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: white;
  position: relative;
  width: 220px;
  height: 30px;
  padding: 5px;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;
}

.miniatura img {
  max-width: 30px;
  max-height: 90%;
}

.miniatura.documento img {
  max-width: 30px;
  max-height: 20px;
}

.file-name {
  text-align: left;
  font-size: 0.8rem;
  color: #333;
  margin-top: 5px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 0 5px;
}

.btn-delete {
  border: none;
  border-top-left-radius: 4px;
  background-color: transparent;
  cursor: pointer;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-delete img {
  width: 20px;
  height: 20px;
}

.icon-no-content {
  z-index: 1;
}
</style>
<style lang="scss">
.pac-container {
  z-index: 9999 !important;
}
</style>
